import React, { createContext, useContext, useEffect, useState } from 'react';

export const MapContext = createContext()


import useApi from '@hooks/useApi';
import useLoader from '@hooks/useLoader';
import AuthContext from './AuthContext';


const URL = "https://eroad.rtdc.gov.mn/geoserver/road/wms?"

export default function MapContextProvider(props)
{
	/* Хамрах хүрээгээр хайж байгаа layer нэр*/
	const layerName = 'scope'
    const [cMap, setCMap] = useState(null)

    const [ showBaseLayer, setShowBaseLayer ] = useState(false)
    const [ shoWMenu, setShowMenu] = useState(false)
	const [layerData, setLayerData] = useState([])

	const layerApi = useApi().geoserver.layer
	// const countryApi = useApi().search

	const { fetchData } = useLoader({})
	const {user} = useContext(AuthContext)
	const getAllLayer = async() => {
		const { success, data } = await fetchData(layerApi.getByUser())
		if(success && data.length > 0)
		{
			setLayerData(data)
		}
	}

	/* Нүүр хэсгийн давхаргыг нэмж хасах функц */
	const handleLayerAdd = (layer_name, workspace, is_check) => {
		var layers = [...layerData]

        layers.map((theme) => {
			var features = theme.features
			features?.map((feature) => {
				if(feature.name == layer_name) {
					feature.is_show = is_check
				}
			})
        })

		setLayerData(layers)
	}

	/* Нүүр хуудасны check хийсэн layer авах */
	function getCheckedLayers() {
		var checked_layers = []
		layerData.forEach((layer) => layer?.features?.filter((feat) => {if (feat?.is_show == true) checked_layers.push({'layer_name': feat, 'attr': feat?.attr})}))

		return checked_layers
	}

	/*  Хайлтаа цэвэрлэх үед ажиллана */
	function getDefaultMap() {
		cMap.removeLayer(layerName)
		cMap.setCenter()

		var checked_layers  = getCheckedLayers()

		if(checked_layers && checked_layers.length > 0) {
			checked_layers.map((layer_name) => {
				cMap.removeLayer(layer_name)

				var wms_layer = cMap.addWMSLayerToMap(layer_name, layer_name, 1)
				if (wms_layer) {
					cMap.map.addLayer(wms_layer.tile)
				}
			})
		}
    }

	useEffect(
		() =>
		{
			getAllLayer()
		},
		[user]
	)

	/* Layer нэмэх хэсэг */
	useEffect(
		() =>
		{
			if(layerData.length > 0 && cMap) {
				layerData?.forEach((layer) => {
					layer?.features?.map(
						(item) =>
						{
							var filters = ''
							if (user?.azzas?.length > 0 && item?.attr) {
								var azza_values = user?.azzas.map(value => `'${value}'`).join(",");
								filters = `${item?.attr} IN (${azza_values})`
							}
							if (item?.is_show && item?.name) {
								var added_layer = cMap.getLayerAdded(item?.name)
								if (!added_layer) {
									cMap.addWMSLayer(item?.name, URL, item?.name, filters)
								}
							} else {
								if (!item?.is_show && item?.name) {
									cMap.removeLayer(item?.name)
								}
							}
						}
					)
				})
			}
		},
		[layerData, cMap]
	)

	/* Бүх давхарга хаах function*/
	const closeAll = () => {
		var all_layers = [...layerData]
		var checked_layers  = getCheckedLayers()
		if(checked_layers && checked_layers.length > 0) {
			checked_layers.map((layer) => {
				cMap.removeLayer(layer?.layer_name?.name)
			})
		}
		all_layers.forEach((layer) => {
			layer?.features?.forEach((feat) => {
				if (feat?.is_show !== false) {
					feat.is_show = false;
				}
			});
		});
		setLayerData(all_layers)
	}

	/* Бүх давхарга нээх function*/
	const openAll = () => {
		var all_layers = [...layerData]
		all_layers.forEach((layer) => {
			layer?.features?.forEach((feat) => {
				if (feat?.is_show !== true) {
					feat.is_show = true;
				}
			});
		});
		setLayerData(all_layers)
	}


    return (
		<MapContext.Provider
			value={{
                showBaseLayer,
				shoWMenu,
				layerData,
				cMap,
				setCMap,
                setShowBaseLayer,
				setShowMenu,
				setLayerData,
				handleLayerAdd,
				// getGeomData,
				getDefaultMap,
				getCheckedLayers,
				closeAll,
				openAll
			}}>
			{props.children}
		</MapContext.Provider>
	)
}