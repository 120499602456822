import { createContext, useState, useEffect } from "react";

// hooks imports
import useApi from "@hooks/useApi"
import useLoader from "@hooks/useLoader"
import { useLocation, useNavigate } from "react-router-dom";

const AuthContext = createContext();

export default AuthContext;

export const AuthProvider = ({ children }) => {

    const [user, setUser] = useState({});
    const [loading, setLoading] = useState(true);
    const [isLogged, setLogged] = useState(false);
    const navigate = useNavigate()

    // Api
    const userApi = useApi().user
    const { fetchData } = useLoader({})

    // Тухайн form дээр hэрэглэгчийн эрхийг буцаана
    function get_user_permissions(FEATURE_CODE) {
        if (user && user?.permissions?.length > 0) {
            var perms = []
            var user_pesrmissions = user?.permissions
            var perm_obj = user_pesrmissions.find((item) => item?.name === FEATURE_CODE)
            if (perm_obj) {
                perms = perm_obj.permissions
            }
            return perms
        }
    }

    const contextData = {
        loading,
        user,
        setUser,
        isLogged,
        setLogged,
        get_user_permissions,
    };

    useEffect(() => {
        getUser()
    }, []);


    async function getUser() {
        try {
            const { success, data } = await fetchData(userApi.logged())
            if (success) {
                var url = window.location.href
                var splitted = url.split('?')
                var splited = splitted[splitted.length - 1]

                if (Object.keys(data).length > 0) {
                    setUser(data)
                    setLogged(true)
                } else {
                    if (splited !== 'yes') {
                        if(url.includes('map/is_mobile')){
                            navigate('/map/is_mobile')
                        }else{
                            navigate('/map')
                        }
                    }
                }
            }
        }
        catch (e) {
            setLogged(false)
        }
        setLoading(false);
    }

    return (
        <AuthContext.Provider value={contextData}>
            {loading ? null : children}
        </AuthContext.Provider>
    );
};
